export default defineNuxtPlugin(() => {
  return {
    provide: {
      scrollTo(element: string | HTMLElement) {
        let el: HTMLElement | undefined = undefined

        if (typeof element === 'string') {
          const found = document.getElementById(element)!
          if (!found) return

          el = found
        } else {
          el = element
        }

        el.scrollIntoView({
          behavior: 'smooth',
        })
      },
    },
  }
})
