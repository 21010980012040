<script setup lang="ts">
import { cx } from 'class-variance-authority'
import { useNav, toFlagIcon } from './nav'
const { locale } = useI18n()
const NuxtLink = resolveComponent('NuxtLink')
const switchLocalePath = useSwitchLocalePath()
const localePath = useLocalePath()
const nav = await useNav()

// console.log('nav', nav)

const groupClass = cx('bg-gray-50 border border-gray-200 rounded py-2')
const linkClass = cx('px-4 py-2 hover:bg-gray-200 transition w-full font-medium text-sm flex items-center space-x-2')
const groupHeadingClass = cx('!text-gray-500 px-4 py-2 text-sm')

const isOpen = ref(false)
function onClose() {
  isOpen.value = false
}
function onOpen() {
  isOpen.value = true
}

onMounted(() => {
  if (typeof window !== 'undefined') {
    window.addEventListener('resize', (e) => {
      onClose()
    })
  }
})
</script>

<template>
  <div class="flex h-full items-center">
    <PopoverRoot :open="isOpen">
      <PopoverTrigger
        @click="onOpen"
        class="bordershadow-input-light relative inline-flex h-8 w-10 items-center justify-center rounded-full text-gray-600"
        :aria-label="$t('header.nav.toggle.open')"
      >
        <LoaderIndicator>
          <Icon name="lucide:menu" size="18px" />
        </LoaderIndicator>
      </PopoverTrigger>

      <ClientOnly>
        <PopoverPortal to="#nav-popover">
          <PopoverContent
            @interact-outside="onClose"
            as="section"
            side="bottom"
            :side-offset="-1"
            :class="[
              'bordershadow-lg-gray absolute -right-6 -top-8 z-[9999] w-72 rounded-lg bg-white p-4',
              'data-[state=open]:data-[side=bottom]:animate-scaleIn data-[state=closed]:data-[side=bottom]:animate-scaleOut origin-top-right will-change-[transform,opacity]',
              'flex max-h-[96.5vh] flex-col space-y-2 overflow-hidden',
            ]"
          >
            <header class="flex items-center justify-between">
              <Heading as="h2" size="small"> Menu </Heading>
              <PopoverClose
                class="bordershadow-input-light inline-flex h-8 w-10 items-center justify-center rounded-full text-gray-600"
                :aria-label="$t('header.nav.toggle.close')"
              >
                <Icon name="lucide:x" size="18px" />
              </PopoverClose>
            </header>
            <main class="flex flex-col space-y-2 overflow-y-auto">
              <template v-for="link of nav" :key="link.label">
                <NuxtLink
                  v-if="link.type === 'internal'"
                  :to="link.to"
                  :class="cx(linkClass, 'rounded')"
                  @click="onClose"
                >
                  <Paragraph size="small">
                    {{ link.label }}
                  </Paragraph>
                </NuxtLink>

                <div v-else-if="link.type === 'subnav' || link.type === 'subnav-work'" :class="groupClass">
                  <Heading as="p" size="small" :class="groupHeadingClass">
                    {{ link.label }}
                  </Heading>

                  <div class="flex flex-col">
                    <NuxtLink v-if="link.highlight" :to="link.highlight.to" :class="linkClass" @click="onClose">
                      <Paragraph size="small">
                        {{ link.highlight.label }}
                      </Paragraph>
                    </NuxtLink>

                    <NuxtLink v-for="l of link.links" :key="l.label" :to="l.to" :class="linkClass" @click="onClose">
                      <Paragraph size="small">
                        {{ l.label }}
                      </Paragraph>

                      <LayoutNavNotification v-if="l.notification">
                        {{ l.notification }}
                      </LayoutNavNotification>
                    </NuxtLink>
                  </div>
                </div>

                <!-- <div v-else-if="link.type === 'locale'" :class="groupClass">
                  <Heading as="p" size="small" :class="groupHeadingClass">
                    {{ link.label }}
                  </Heading>

                  <NuxtLink v-for="l of link.locales" :key="l.locale.code" :to="switchLocalePath(l.locale.code)"
                    :class="cx(linkClass, 'relative flex items-center justify-between overflow-hidden')" @click="onClose">
                    <div v-if="l.locale.code === locale"
                      class="bg-main-500 absolute -left-1 -mt-0.5 h-2 w-2 rounded-full" />

                    <Paragraph size="small">
                      <span class="sr-only">
                        {{ l.locale.code === locale ? $t('header.current_lang') : $t('header.switch_to_lang') }}:
                      </span>

                      {{ l.locale.name }}
                    </Paragraph>

                    <Icon :name="toFlagIcon(l.locale.code)" />
                  </NuxtLink>
                </div> -->
              </template>
            </main>

            <footer>
              <Action intent="main" size="sm" as="link" :to="localePath('/contact')" @click="onClose">
                {{ $t('header.nav.contact') }}
              </Action>
            </footer>
          </PopoverContent>
        </PopoverPortal>
      </ClientOnly>
    </PopoverRoot>
  </div>
</template>
