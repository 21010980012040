<script setup lang="ts">
// @ts-expect-error virtual file
import { globalMiddleware } from '#build/middleware'

const indicator = useLoadingIndicator({
  duration: 1000,
  throttle: 100
})
const router = useRouter()
const nuxtApp = useNuxtApp()

globalMiddleware.unshift(indicator.start)
router.onError(() => {
  indicator.finish()
})
router.beforeResolve((to, from) => {
  if (to === from || to.matched.every((comp, index) => comp.components && comp.components?.default === from.matched[index]?.components?.default)) {
    indicator.finish()
  }
})

router.afterEach((_to, _from, failure) => {
  if (failure) {
    indicator.finish()
  }
})

const unsubPage = nuxtApp.hook('page:finish', indicator.finish)
const unsubError = nuxtApp.hook('vue:error', indicator.finish)

onBeforeUnmount(() => {
  const index = globalMiddleware.indexOf(indicator.start)
  if (index >= 0) {
    globalMiddleware.splice(index, 1)
  }
  unsubPage()
  unsubError()
  indicator.clear()
})


</script>
<template>
  <div>
    <Icon v-if="indicator.isLoading.value" name="lucide:loader-2" class="animate-spinLoader !text-main-600" />
    <slot v-else />
  </div>
</template>