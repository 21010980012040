import revive_payload_client_Frd8gASYWa from "/home/runner/work/webamboos/webamboos/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_3Bi0E2Ktsf from "/home/runner/work/webamboos/webamboos/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CrWB4n4PyO from "/home/runner/work/webamboos/webamboos/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_2Y3EVkdaoz from "/home/runner/work/webamboos/webamboos/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_T5aD6w6QZh from "/home/runner/work/webamboos/webamboos/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/webamboos/webamboos/web/.nuxt/components.plugin.mjs";
import prefetch_client_5ZRYOjuei0 from "/home/runner/work/webamboos/webamboos/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_fUdfgsNma6 from "/home/runner/work/webamboos/webamboos/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/i18n.mjs";
import strapi_KSPFj1nzXf from "/home/runner/work/webamboos/webamboos/node_modules/@nuxtjs/strapi/dist/runtime/plugins/strapi.mjs";
import composition_BfDtacIhbF from "/home/runner/work/webamboos/webamboos/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_huatSqtcYP from "/home/runner/work/webamboos/webamboos/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_kCUITUWXUN from "/home/runner/work/webamboos/webamboos/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_yxgjJ9XxnV from "/home/runner/work/webamboos/webamboos/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import _01_posthog_client_njXv1AyeXs from "/home/runner/work/webamboos/webamboos/web/plugins/01.posthog.client.ts";
import _02_cookieconsent_client_ewjX1AE2ia from "/home/runner/work/webamboos/webamboos/web/plugins/02.cookieconsent.client.ts";
import scrollto_client_BPG2kQbA9g from "/home/runner/work/webamboos/webamboos/web/plugins/scrollto.client.ts";
import vee_validate_client_P40JVl0wNI from "/home/runner/work/webamboos/webamboos/web/plugins/vee-validate.client.ts";
export default [
  revive_payload_client_Frd8gASYWa,
  unhead_3Bi0E2Ktsf,
  router_CrWB4n4PyO,
  _0_siteConfig_2Y3EVkdaoz,
  payload_client_T5aD6w6QZh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5ZRYOjuei0,
  i18n_fUdfgsNma6,
  strapi_KSPFj1nzXf,
  composition_BfDtacIhbF,
  i18n_huatSqtcYP,
  chunk_reload_client_kCUITUWXUN,
  check_outdated_build_client_yxgjJ9XxnV,
  _01_posthog_client_njXv1AyeXs,
  _02_cookieconsent_client_ewjX1AE2ia,
  scrollto_client_BPG2kQbA9g,
  vee_validate_client_P40JVl0wNI
]