<script setup lang="ts">
const { footer } = useFooter()

const badges = footer.value.badges.map((badge) => ({
  name: badge.name,
  img: badge.image.url,
  url: badge.url,
}))
</script>

<template>
  <div
    class="screen-content grid grid-cols-2 gap-x-4 gap-y-4 sm:flex sm:flex-wrap sm:justify-center sm:gap-x-16 md:justify-start"
  >
    <a
      v-for="badge of badges"
      :key="badge.img"
      :href="badge.url"
      target="_blank"
      rel="noopener noreferrer"
      class="h-24 sm:h-32"
    >
      <NuxtImg
        :src="badge.img"
        loading="lazy"
        :alt="`${$t('footer.badges.badge')} - ${badge.name}`"
        class="h-full w-full object-contain grayscale transition hover:grayscale-0"
        :title="badge.name"
      />
    </a>
  </div>
</template>
