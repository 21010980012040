import type { StrapiLocale } from '@nuxtjs/strapi/dist/runtime/types'
import type { Project_Plain } from '@webamboos/admin'
import { uniqBy } from 'lodash'

export function useIndustries() {
  const { localeProperties } = useI18n()
  const { find } = useStrapi()

  const fetch = async () => {
    const { data } = await find('projects', {
      locale: localeProperties.value.code as StrapiLocale,
      populate: {
        industries: true,
      },
      fields: ['id'],
      pagination: {
        start: 0,
        limit: 100,
      },
    })

    const projects = data as unknown as Project_Plain[]

    const industries = projects
      .map((project) => project.industries)
      .flat()
      .sort((industryA, industryB) => {
        const idxA = Number(industryA.index)
        const idxB = Number(industryB.index)

        if (isNaN(idxA) && isNaN(idxB)) return 0
        if (isNaN(idxA) && !isNaN(idxB)) return 1
        if (!isNaN(idxA) && isNaN(idxB)) return -1

        return idxA - idxB
      })

    const uniqueIndustries = uniqBy(industries, 'slug')

    return uniqueIndustries
  }

  return {
    fetch,
  }
}
