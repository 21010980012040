import { defineRule } from 'vee-validate'

export default defineNuxtPlugin(() => {
  defineRule('required', (value: string) => {
    if (!value || !value.length) {
      return 'This field is required'
    }
    return true
  })
  defineRule('email', (value: string) => {
    // Field is empty, should pass
    if (!value || !value.length) {
      return true
    }

    // Check if email
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
      return 'This field must be a valid email'
    }
    return true
  })
})
