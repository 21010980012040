<script setup lang="ts">
const localePath = useLocalePath()
</script>

<template>
  <nav id="nav" class="fixed top-0 z-50 h-16 w-screen border-b border-black/5 bg-white/80 backdrop-blur-md">
    <div class="screen-guides flex h-full items-center justify-between">
      <div class="flex items-center">
        <NuxtLink :to="localePath('/')" aria-label="Go to homepage">
          <LayoutLogo schema="color" class="h-8 pb-1 md:h-9" />
        </NuxtLink>
        <!-- <span
          class="text-xs bg-main-100 rounded-full border h-6 border-main-200 px-2 text-main-500 mt-3 ml-2 flex items-center"
        >
          Preview
        </span> -->
      </div>

      <LayoutNavDesktop class="hidden md:flex" />
      <LayoutNavMobile class="flex md:hidden" />
    </div>
    <div id="nav-popover"></div>
  </nav>
</template>
