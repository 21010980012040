<script setup lang="ts"></script>

<template>
  <div class="gap-x-2 sm:gap-x-4 text-gray-400 flex flex-row items-center pt-2">
    <a href="https://www.facebook.com/webamboos" title="Facebook" target="_blank">
      <Icon name="fa6-brands:facebook" size="21" class="hover:text-gray-900 transition" />
    </a>
    <a href="https://www.linkedin.com/company/webamboos" title="LinkedIn" target="_blank">
      <Icon name="fa6-brands:linkedin" size="21" class="hover:text-gray-900 transition" />
    </a>
    <a href="https://www.instagram.com/webamboos" title="Instagram" target="_blank">
      <Icon name="fa6-brands:instagram" size="21" class="hover:text-gray-900 transition" />
    </a>
    <a href="https://twitter.com/webamboos" title="X" target="_blank">
      <Icon name="simple-icons:x" size="21" class="hover:text-gray-900 transition" />
    </a>
  </div>
</template>
