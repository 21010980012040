<script setup lang="ts">
import type { VariantProps } from 'class-variance-authority'
import {  cva } from 'class-variance-authority'
import WrapBalancer from 'vue-wrap-balancer'

const style = cva([], {
  variants: {
    size: {
      large: 'text-gray-600 text-lg md:text-xl leading-snug tracking-normal',
      medium: 'text-gray-800 text-base lg:text-lg leading-normal tracking-normal',
      normal: 'text-gray-800 text-sm lg:text-base leading-snug tracking-normal',
      small: 'text-gray-700 text-sm leading-snug tracking-normal',
    },
    weight: {
      normal: 'font-normal',
      bold: 'font-bold',
      medium: 'font-medium',
    },
    align: {
      left: 'text-left',
      center: 'text-center',
      right: 'text-right',
    },
  },
})
type StyleProps = VariantProps<typeof style>

withDefaults(
  defineProps<{
    size?: StyleProps['size']
    weight?: StyleProps['weight']
    align?: StyleProps['align']
    as?: keyof HTMLElementTagNameMap
  }>(),
  {
    size: 'normal',
    as: 'p',
  },
)
</script>

<template>
  <component :is="as" :class="style({ class: $props.class as string, size, weight, align })">
      <slot />
  </component>
</template>
