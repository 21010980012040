
import * as ipxRuntime$l0kXp4JmPJ from '/home/runner/work/webamboos/webamboos/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 360,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1264,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "webamboos.com",
    "assets.webamboos.com"
  ],
  "alias": {
    "/local": "https://webamboos.com"
  },
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$l0kXp4JmPJ, defaults: {"maxAge":31536000} }
}
        