<script setup lang="ts">
const nuxtLink = resolveComponent('NuxtLink')

const props = defineProps<{
  type: 'internal' | 'external'
  to: string
}>()

const attrs = {
  to: props.type === 'internal' ? props.to : undefined,
  href: props.type === 'external' ? props.to : undefined,
}
</script>

<template>
  <component
    :is="type === 'internal' ? nuxtLink : 'a'"
    :target="type === 'external' ? '_blank' : undefined"
    v-bind="attrs"
    class="text-gray-800 hover:text-gray-500 transition"
  >
    <slot />
  </component>
</template>
