<script setup lang="ts">
import type { NuxtError } from 'nuxt/app'
const { locale } = useI18n()

const props = defineProps<{
  error: NuxtError
}>()

const { stack, ...error } = props.error
</script>

<template>
  <div class="flex min-h-screen w-full flex-col">
    <LayoutHeader id="header" schema="color" :key="locale" />

    <Container class="container flex-1 px-16 pb-64 pt-32">
      <Heading as="h1" size="medium" class="mb-8">
        {{ $t(`error.${error.statusCode}`) }}
      </Heading>

      <DevOnly class="w-full overflow-scroll rounded-lg bg-gray-100 p-4">
        <code>
          <pre>{{ error }}</pre>
        </code>
        <div class="h-8"></div>
        <code v-html="stack"></code>
      </DevOnly>
    </Container>

    <LayoutFooter :key="locale" />
  </div>
</template>
