import * as z from 'zod'

/** Configurez default Zod configurations, such as locale-based error messages. */
export function useZodConfig() {
  const { t } = useI18n()

  const msg = (string: string) => ({
    message: string,
  })

  const errorMap: z.ZodErrorMap = (error, ctx) => {
    switch (error.code) {
      case z.ZodIssueCode.invalid_type: {
        if (['nan', 'null', 'undefined'].includes(error.received))
          return msg(t('validation.required'))

        break
      }

      case z.ZodIssueCode.too_small: {
        if (error.type === 'string')
          return msg(t('validation.text_min', { min: error.minimum }))
        break
      }
      case z.ZodIssueCode.too_big: {
        if (error.type === 'string')
          return msg(t('validation.text_max', { max: error.maximum }))
        break
      }
    }

    return { message: ctx.defaultError }
  }

  z.setErrorMap(errorMap)

  return { errorMap }
}
