import { type StrapiLocale } from '@nuxtjs/strapi/dist/runtime/types'
import type { Project_Plain, Technology_Plain } from '@webamboos/admin'
import { uniqBy } from 'lodash'

export function useTechnologies() {
  const { localeProperties } = useI18n()
  const { find } = useStrapi()

  const fetch = async () => {
    const { data } = await find('technologies', {
      locale: localeProperties.value.code as StrapiLocale,
      populate: {
        technologies: true,
      },
      pagination: {
        start: 0,
        limit: 100,
      },
    })

    const technologies = data as unknown as Technology_Plain[]

    return technologies
  }

  const fetchBySlug = async (slug: string) => {
    const { data } = await find('technologies', {
      locale: localeProperties.value.code as StrapiLocale,
      populate: {
        technologies: true,
        image: true,
      },
      pagination: {
        start: 0,
        limit: 100,
      },
      filters: {
        slug,
      },
    })

    const technology = data as unknown as Technology_Plain[]
    return {
      technology: technology.map((tech) => ({
        ...tech,
      }))[0],
    }
  }

  return {
    fetch,
    fetchBySlug,
  }
}
