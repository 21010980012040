import posthog, { type PostHog } from 'posthog-js'

export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig()

  let client: PostHog

  client = posthog.init(runtimeConfig.public.posthogPublicKey, {
    api_host: runtimeConfig.public.posthogHost,
    opt_in_site_apps: true,
    autocapture: true,
    disable_session_recording: false,
    loaded: (posthog) => {
      if (import.meta.env.MODE === 'development') posthog.debug()
    },
  })!

  client.opt_out_capturing()

  const router = useRouter()
  router.afterEach((to) => {
    posthog.capture('$pageview', {
      current_url: to.fullPath,
    })
  })

  return {
    provide: {
      posthog: client,
    },
  }
})
