import 'vanilla-cookieconsent/dist/cookieconsent.css'
import 'vanilla-cookieconsent/src/cookieconsent.js'

declare global {
  interface Window {
    initCookieConsent: () => { run: (options: any) => void }
  }
}

const consents = {
  analytics: 'analytics',
  necessary: 'necessary',
} as const

export default defineNuxtPlugin(() => {
  const cookieConsent = window.initCookieConsent()
  const { $posthog } = useNuxtApp()

  const consentsGiven: (keyof typeof consents)[] = [consents.necessary]
  const consent = (key: keyof typeof consents) => consentsGiven.includes(key)

  cookieConsent.run({
    current_lang: 'en',
    autoclear_cookies: true,
    page_scripts: true,
    cookie_expiration: 365,
    gui_options: {
      consent_modal: {
        layout: 'cloud',
        position: 'bottom center',
        transition: 'slide',
        swap_buttons: false,
      },
    },
    onAccept: function (context: any) {
      if (context.level.includes(consents.analytics)) {
        consentsGiven.push(consents.analytics)
        $posthog.opt_in_capturing()
      }
    },
    languages: {
      en: {
        consent_modal: {
          title: 'We use cookies!',
          description:
            'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
          primary_btn: {
            text: 'Accept all',
            role: 'accept_all',
          },
          secondary_btn: {
            text: 'Reject all',
            role: 'accept_necessary',
          },
        },
        settings_modal: {
          title: 'Cookie preferences',
          save_settings_btn: 'Save settings',
          accept_all_btn: 'Accept all',
          reject_all_btn: 'Reject all',
          close_btn_label: 'Close',
          cookie_table_headers: [{ col1: 'Name' }, { col2: 'Domain' }, { col3: 'Expiration' }, { col4: 'Description' }],
          blocks: [
            {
              title: 'Cookie usage 📢',
              description:
                'We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="#" class="cc-link">privacy policy</a>.',
            },
            {
              title: 'Strictly necessary cookies',
              description:
                'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
              toggle: {
                value: consents.necessary,
                enabled: true,
                readonly: true,
              },
            },
            {
              title: 'Performance and Analytics cookies',
              description: 'These cookies allow the website to remember the choices you have made in the past',
              toggle: {
                value: consents.analytics,
                enabled: true,
                readonly: false,
              },
              cookie_table: [
                {
                  col1: '^ph_phc_',
                  col2: '*.webamboos.com',
                  col3: '1 year',
                  col4: 'PostHog Analytics',
                  is_regex: true,
                },
              ],
            },
            {
              title: 'More information',
              description:
                'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="/contact">contact us</a>.',
            },
          ],
        },
      },
    },
  })

  return {
    provide: {
      consent,
    },
  }
})
