<script setup lang="ts">
const white = '#FFFFFF'
const black = '#222222'
const primaryColor = '#ABEF96'
const secondaryColor = '#2AAEA3'

interface Theme {
  color: string
  white: string
  black: string
}

const props = withDefaults(
  defineProps<{
    schema?: keyof Theme
  }>(),
  { schema: 'color' },
)

const colorA = ref<Theme>({ color: primaryColor, white, black })
const colorB = ref<Theme>({ color: secondaryColor, white, black })

const cA = computed<string>(() => colorA.value[props.schema])
const cB = computed<string>(() => colorB.value[props.schema])
</script>

<template>
  <svg height="39" viewBox="0 0 182 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M55.8817 20.0204C53.6038 20.104 51.6499 20.9294 50.0616 22.4759V14.9143C50.0616 14.674 49.8666 14.4824 49.6298 14.4824H47.5922C47.3519 14.4824 47.1603 14.6775 47.1603 14.9143V29.0726V29.1841C47.0976 31.7092 48.0067 33.9175 49.8527 35.746C51.6325 37.5084 53.7188 38.4001 56.0628 38.4001C56.1778 38.4001 56.2927 38.3966 56.4076 38.3931C58.8457 38.299 60.9251 37.3586 62.5934 35.5893C64.2757 33.8025 65.129 31.6605 65.129 29.2224C65.129 26.6903 64.2026 24.4751 62.381 22.643C60.5907 20.8075 58.4034 19.9263 55.8817 20.0204ZM62.165 29.0482C62.1929 30.7584 61.646 32.2108 60.4897 33.4856C59.3542 34.7394 57.9994 35.3873 56.3449 35.4744C54.6313 35.5579 53.1754 34.9624 51.9076 33.6562C50.6468 32.3327 50.0651 30.8176 50.1209 29.0169C50.1487 27.3451 50.7339 25.931 51.9076 24.691C53.0326 23.5068 54.4223 22.9043 56.0384 22.9043C57.7312 22.9043 59.1313 23.4999 60.3225 24.7259C61.5346 25.9484 62.1371 27.359 62.165 29.0482Z"
      :fill="cA"
    />
    <path
      d="M81.8369 22.852C80.1511 21.0653 78.0509 20.1109 75.5919 20.0204C73.0528 19.9298 70.8516 20.811 69.0648 22.6396C67.2606 24.4542 66.3446 26.6694 66.3446 29.2224C66.3446 31.6605 67.1979 33.8025 68.8837 35.5893C70.5486 37.3587 72.6209 38.2991 75.0416 38.3931C75.1531 38.3966 75.261 38.4001 75.369 38.4001C77.6887 38.4001 79.7193 37.5607 81.412 35.9028V37.5746C81.412 37.8149 81.607 38.0065 81.8439 38.0065H83.8536C84.0939 38.0065 84.2854 37.8114 84.2854 37.5746V29.205C84.376 26.7774 83.5505 24.6388 81.8369 22.852ZM81.3528 29.0169C81.3806 30.8246 80.7885 32.3432 79.5347 33.6562C78.2912 34.9624 76.8423 35.5614 75.0973 35.4744C73.4638 35.3908 72.0706 34.722 70.9526 33.4856C69.8311 32.2491 69.2773 30.7549 69.3051 29.0483C69.333 27.4147 69.8589 26.0599 70.9212 24.9035C72.088 23.6322 73.732 22.9078 75.4352 22.9078C75.4456 22.9078 75.4561 22.9078 75.4665 22.9078C77.0722 22.9147 78.448 23.5173 79.566 24.6945C80.7398 25.9345 81.3249 27.3486 81.3528 29.0169Z"
      :fill="cA"
    />
    <path
      d="M104.184 20.0796C101.787 19.8776 99.8161 20.8389 98.3288 22.9391C96.8381 20.8389 94.8702 19.8741 92.4739 20.0796C90.5688 20.2328 89.0049 20.9573 87.8346 22.2286C86.5807 23.5521 85.9434 25.3458 85.9434 27.561V37.5711C85.9434 37.8114 86.1384 38.003 86.3753 38.003H88.5312C88.7715 38.003 88.9631 37.808 88.9631 37.5711V27.2963C88.9631 26.0424 89.294 25.0184 89.9766 24.1686C90.6558 23.3187 91.5196 22.9078 92.6168 22.9078C93.7452 22.9078 94.7379 23.2874 95.5668 24.0362C96.3993 24.7642 96.8033 25.6663 96.8033 26.7948V37.5746C96.8033 37.8149 96.9983 38.0065 97.2352 38.0065H99.419C99.6593 38.0065 99.8509 37.8114 99.8509 37.5746V26.7913C99.8509 25.6698 100.248 24.7677 101.056 24.0328C101.923 23.2735 102.895 22.9043 104.034 22.9043C105.135 22.9043 106.033 23.3292 106.705 24.1651C107.395 25.0289 107.747 26.0808 107.747 27.2928V37.5711C107.747 37.8114 107.942 38.003 108.179 38.003H110.335C110.575 38.003 110.767 37.808 110.767 37.5711V27.561C110.767 25.3667 110.122 23.573 108.847 22.2286C107.646 20.9573 106.075 20.2328 104.184 20.0796Z"
      :fill="cA"
    />
    <path
      d="M121.149 20.0204C118.871 20.104 116.918 20.9294 115.329 22.4759V14.9143C115.329 14.674 115.134 14.4824 114.897 14.4824H112.86C112.62 14.4824 112.428 14.6775 112.428 14.9143V29.1806C112.369 31.7058 113.274 33.914 115.12 35.7425C116.9 37.5049 118.986 38.3966 121.33 38.3966C121.445 38.3966 121.56 38.3931 121.675 38.3896C124.113 38.2956 126.193 37.3552 127.861 35.5858C129.543 33.799 130.4 31.657 130.4 29.2189C130.4 26.6868 129.474 24.4716 127.652 22.6396C125.858 20.8075 123.668 19.9263 121.149 20.0204ZM127.436 29.0482C127.464 30.7584 126.917 32.2108 125.761 33.4856C124.625 34.7394 123.27 35.3873 121.616 35.4744C119.902 35.5579 118.447 34.9624 117.175 33.6562C115.914 32.3327 115.333 30.8176 115.388 29.0169C115.416 27.3451 116.001 25.931 117.175 24.691C118.297 23.5068 119.69 22.9043 121.306 22.9043C122.999 22.9043 124.399 23.4999 125.59 24.7259C126.806 25.9449 127.408 27.359 127.436 29.0482Z"
      :fill="cA"
    />
    <path
      d="M140.863 20.0203C138.355 19.9297 136.171 20.8214 134.364 22.6709C132.56 24.4855 131.644 26.7007 131.644 29.2537C131.644 31.7127 132.486 33.8547 134.151 35.6206C135.827 37.4004 137.909 38.3338 140.337 38.393C140.456 38.3965 140.57 38.4 140.689 38.4C143.036 38.4 145.105 37.5188 146.836 35.7738C148.661 33.9383 149.588 31.7336 149.588 29.2223C149.588 26.7843 148.735 24.6422 147.052 22.8554C145.391 21.0652 143.312 20.1143 140.863 20.0203ZM146.652 29.0482C146.68 30.835 146.084 32.3431 144.834 33.6597C143.618 34.9658 142.173 35.5614 140.396 35.4743H140.393C138.752 35.4186 137.397 34.7777 136.255 33.5169C135.134 32.2804 134.58 30.7862 134.608 29.0795C134.635 27.352 135.228 25.9274 136.419 24.7293C137.617 23.5242 138.993 22.9356 140.619 22.9356C140.658 22.9356 140.696 22.9356 140.731 22.9356C142.354 22.9356 143.695 23.5207 144.834 24.7223C146 25.9553 146.596 27.3694 146.652 29.0482Z"
      :fill="cA"
    />
    <path
      d="M160.371 20.0203C157.863 19.9297 155.68 20.8214 153.872 22.6709C152.068 24.4855 151.152 26.7007 151.152 29.2537C151.152 31.7127 151.995 33.8547 153.659 35.6206C155.335 37.4004 157.418 38.3338 159.845 38.393C159.964 38.3965 160.079 38.4 160.197 38.4C162.545 38.4 164.613 37.5188 166.344 35.7738C168.17 33.9383 169.093 31.7336 169.093 29.2223C169.093 26.7843 168.239 24.6422 166.557 22.8554C164.903 21.0652 162.82 20.1143 160.371 20.0203ZM166.16 29.0482C166.188 30.835 165.592 32.3431 164.342 33.6631C163.126 34.9693 161.674 35.5614 159.904 35.4778H159.901C158.26 35.422 156.906 34.7812 155.763 33.5203C154.642 32.2839 154.088 30.7897 154.116 29.083C154.144 27.3555 154.736 25.9309 155.927 24.7328C157.125 23.5277 158.497 22.939 160.127 22.939C160.166 22.939 160.204 22.939 160.239 22.939C161.862 22.939 163.203 23.5242 164.342 24.7258C165.512 25.9553 166.108 27.3694 166.16 29.0482Z"
      :fill="cA"
    />
    <path
      d="M181.983 32.6008C181.826 30.2324 180.541 28.6128 178.155 27.7943L176.212 27.143C174.85 26.6867 174.223 25.9588 174.3 24.9069C174.324 23.8272 175.209 22.9634 176.306 22.9391C177.351 22.9391 178.43 23.5521 178.43 24.5761V25.3075H181.502L181.45 24.4298C181.356 23.1515 180.768 22.0753 179.705 21.2254C178.657 20.3651 177.514 19.9576 176.288 20.0203C174.902 20.083 173.687 20.6124 172.673 21.6016C171.684 22.5977 171.214 23.8098 171.276 25.2065C171.37 27.5261 172.541 29.0586 174.756 29.7587L176.724 30.3787C178.232 30.8663 178.949 31.7231 178.925 32.9944C178.897 33.7711 178.681 34.3528 178.26 34.7742C177.776 35.2618 177.138 35.5022 176.257 35.53C175.651 35.5579 175.062 35.2932 174.519 34.7429C173.962 34.1821 173.68 33.6666 173.68 33.2104V32.7402C173.68 32.4998 173.485 32.3083 173.248 32.3083H171.095C170.98 32.3083 170.869 32.357 170.785 32.4371C170.705 32.5207 170.656 32.6322 170.66 32.7471C170.681 34.2413 171.186 35.544 172.165 36.6237C173.213 37.7452 174.491 38.3582 175.958 38.4522C176.079 38.4592 176.198 38.4627 176.316 38.4627C177.793 38.4627 179.124 37.9194 180.28 36.8466C181.537 35.6484 182.109 34.2204 181.983 32.6008Z"
      :fill="cA"
    />
    <path
      d="M24.8762 20.4382H22.6646C22.4869 20.4382 22.3232 20.5497 22.2605 20.7169L17.3878 33.6945L13.9223 22.7788C13.8665 22.5977 13.6994 22.4793 13.5113 22.4793H11.7384C11.5504 22.4793 11.3867 22.6012 11.3274 22.7788L7.80964 33.7746L3.02054 20.7204C2.95784 20.5497 2.79414 20.4382 2.61651 20.4382H0.432683C0.289881 20.4382 0.157528 20.5079 0.0774194 20.6228C-0.00268916 20.7378 -0.023587 20.8875 0.0286577 21.0199L6.25971 37.7278C6.3224 37.8949 6.4861 38.0099 6.66374 38.0099H9.08093C9.27249 38.0099 9.43967 37.888 9.49192 37.7034L12.5604 27.6585L15.8135 37.7103C15.8727 37.888 16.0364 38.0099 16.2245 38.0099H18.7009C18.8785 38.0099 19.0422 37.8984 19.1049 37.7278L25.2803 21.0164C25.329 20.8841 25.3116 20.7343 25.228 20.6193C25.1479 20.5079 25.0156 20.4382 24.8762 20.4382Z"
      :fill="cB"
    />
    <path
      d="M36.0287 19.9646C33.4862 19.8114 31.2292 20.703 29.3205 22.6117C27.4502 24.4786 26.5341 26.7564 26.5968 29.3756C26.6282 31.8277 27.5616 33.9906 29.3832 35.8087C31.1526 37.5467 33.2249 38.4244 35.5446 38.4244C35.5829 38.4244 35.6247 38.4244 35.6665 38.4244C37.5682 38.3931 39.2435 37.8393 40.79 36.7317C42.2946 35.652 43.3813 34.1822 44.0222 32.3536C44.0675 32.2212 44.0466 32.0749 43.9665 31.96C43.8863 31.8451 43.754 31.7789 43.6147 31.7789H41.4239C41.2602 31.7789 41.1069 31.8764 41.0373 32.0262C39.9471 34.325 38.216 35.4534 35.7431 35.4813C34.1584 35.5092 32.7965 35.0041 31.5775 33.9383C30.5047 33.0014 29.8464 31.7928 29.6166 30.3439H44.057C44.2938 30.3439 44.4889 30.1489 44.4889 29.912V28.78C44.4889 26.5962 43.6634 24.604 42.0334 22.8555C40.3546 21.0896 38.338 20.1179 36.0287 19.9646ZM29.6549 27.5715C30.0032 26.2096 30.6684 25.1403 31.682 24.3114C32.8279 23.3745 34.1584 22.9077 35.6526 22.9391C37.1642 22.967 38.4738 23.4476 39.5396 24.3636C40.5078 25.1961 41.1731 26.2758 41.5144 27.5715H29.6549V27.5715Z"
      :fill="cB"
    />
    <path
      v-if="schema === 'color'"
      d="M60.7963 22.6465C59.0234 20.8144 56.8535 19.9297 54.3562 20.0238C52.1028 20.1074 50.1697 20.9294 48.5989 22.4758V14.9177C48.5989 14.6809 48.4038 14.4858 48.167 14.4858H46.1469C45.91 14.4858 45.715 14.6809 45.715 14.9177V29.076V29.1875C45.6558 31.7127 46.5509 33.9209 48.3829 35.7494C50.1453 37.5118 52.2142 38.4035 54.5374 38.4035C54.6523 38.4035 54.7638 38.4 54.8787 38.3965C57.2959 38.3025 59.3578 37.3586 61.0053 35.5927C62.6736 33.8059 63.5165 31.6639 63.5165 29.2258C63.5165 26.6937 62.6005 24.4785 60.7963 22.6465ZM60.5769 29.0517C60.6047 30.7653 60.0614 32.2142 58.919 33.4925C57.7974 34.7463 56.453 35.3942 54.8195 35.4778C53.1163 35.5614 51.6848 34.9658 50.4274 33.6631C49.1805 32.3396 48.6024 30.8245 48.6581 29.0238C48.686 27.352 49.2641 25.9379 50.4274 24.6979C51.5385 23.5137 52.9143 22.9147 54.5165 22.9147C56.1918 22.9147 57.578 23.5102 58.7553 24.7363C59.9534 25.9483 60.549 27.3624 60.5769 29.0517Z"
      :fill="cB"
    />
    <path
      d="M66.383 14.1515C64.5161 14.1306 62.6423 14.2699 60.7998 14.5973C59.0269 14.9108 57.2854 15.4088 55.6066 16.0567C57.8079 14.1341 59.6155 11.7587 60.8625 9.11509C62.1442 6.4123 62.8338 3.43784 62.9244 0.452921V0.418091L62.893 0.435506C61.4058 1.37243 60.03 2.48698 58.804 3.7513C57.5745 5.01214 56.5122 6.42972 55.624 7.9483C53.8721 11.0029 52.8969 14.4893 52.7715 18.0037V18.0141H52.7819C53.489 18.0907 54.1995 18.129 54.91 18.129C56.9685 18.136 59.0269 17.7947 60.9774 17.1294C61.9526 16.7986 62.9 16.3876 63.8091 15.8999C64.7181 15.4158 65.5819 14.8411 66.4074 14.2281L66.5049 14.155L66.383 14.1515Z"
      :fill="cA"
    />
  </svg>
</template>
