import type { StrapiLocale } from '@nuxtjs/strapi/dist/runtime/types'
import type { Job_Plain } from '@webamboos/admin'

export function useJobs() {
  const { localeProperties, t } = useI18n()
  const { find } = useStrapi()

  const fetch = async () => {
    const { data } = await find('jobs', {
      locale: localeProperties.value.code as StrapiLocale,
      populate: ['icon', 'single_job_person'],
    })

    const jobs = data as unknown as Job_Plain[]

    return jobs
  }

  const fetchBySlug = async (slug: string) => {
    const { data } = await find('jobs', {
      locale: localeProperties.value.code as StrapiLocale,
      populate: ['icon', 'single_job_person'],
    })
    const jobs = data as unknown as Job_Plain[]
    const currentJob = jobs.find((job) => job.slug === slug)

    if (!currentJob) return null

    const currentIndex = jobs.findIndex((job) => job.slug === slug)
    const jobsNumber = data.length
    const previousSlugIndex = currentIndex > 0 ? currentIndex - 1 : jobsNumber - 1
    const nextSlugIndex = currentIndex < jobsNumber - 1 ? currentIndex + 1 : 0

    return {
      job: currentJob,
      previousSlug: jobs[previousSlugIndex].slug,
      nextSlug: jobs[nextSlugIndex].slug,
      meta: {
        title: currentJob.title,
        description: `${t('careers.jobs.single.description.1')} ${currentJob.title}${t(
          'careers.jobs.single.description.2',
        )}`,
      },
    }
  }

  return {
    fetch,
    fetchBySlug,
  }
}
