import type { StrapiLocale } from '@nuxtjs/strapi/dist/runtime/types'
import type { Footer_Plain } from '@webamboos/admin'

export function useFooter() {
  const { findOne } = useStrapi()
  const { localeProperties } = useI18n()

  const locale = computed(() => localeProperties.value.code as StrapiLocale)

  /** This is a shared state between server and client.
   * Should be used in components that need data from the homepage instead of passing around props. */
  const footer = useState<Footer_Plain>(`footer_${locale.value}`)

  const fetchFooter = async () => {
    const result = await findOne('footer', {
      locale: locale.value,
      populate: {
        badges: {
          populate: { image: true },
        },
      },
    })
    const attrs = result.data as unknown as Footer_Plain

    footer.value = attrs
    return attrs
  }

  return {
    fetchFooter,
    footer,
  }
}
