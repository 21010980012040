<script setup lang="ts">
import { BalancerProvider } from 'vue-wrap-balancer'
useZodConfig()

const config = useRuntimeConfig()
const { localeProperties } = useI18n()

useHead({
  htmlAttrs: {
    lang: localeProperties.value.iso,
  },
})

useSchemaOrg([
  defineWebSite({
    name: 'Webamboos',
    logo: `${config.public.baseUrl}/favicon.png`,
  }),
  defineWebPage(),
])
</script>

<template>
  <NuxtLayout>
    <div class="min-h-screen overflow-x-hidden">
      <BalancerProvider>
        <NuxtLoadingIndicator class="hidden lg:!block" color="#0D9488"/>
        <NuxtPage :key="$route.fullPath" />
      </BalancerProvider>
    </div>
  </NuxtLayout>
</template>
